import { ROUTING } from "@/routing/routes";
import { getVasDocument } from "@/service/CmsService";
import { eventProvider, legacyIdMap, translation, usePermissions } from "@mercury/common";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default function useCardsData() {
  const { lng } = translation.useTranslation();
  const { permissions } = usePermissions();
  const router = useRouter();

  const goToLegacy = (legacyId: string) => {
    const eventProviderInstance = eventProvider.getEventProvider();
    eventProviderInstance.emit(eventProvider.EventChannel.brandSelectionModal, {
      show: false,
      legacyId: legacyId,
      brand: "",
    });
  };

  const cardsData = computed(() => {
    const cardsDataTemp = [];

    if (permissions.value) {
      if (permissions.value.orderManagement) {
        cardsDataTemp.push({
          id: "order",
          icon: "/assets/icons/navigation/edit",
          title: "internal.title_order",
          description: "internal.description_order",
          onClick: () => router.push(ROUTING.order.url),
        });
      }
      if (permissions.value.ecommerceInternal) {
        cardsDataTemp.push({
          id: "ecommerce",
          icon: "/assets/icons/navigation/bag",
          title: "internal.title_ecommerce",
          description: "internal.description_ecommerce",
          onClick: () => router.push(ROUTING.ecommerce.url),
        });
      }
      if (permissions.value.orderInquiry) {
        cardsDataTemp.push({
          id: "inquiry",
          icon: "/assets/icons/navigation/file",
          title: "internal.title_inquiry",
          description: "internal.description_inquiry",
          onClick: () => router.push(ROUTING.inquiry.url),
        });
      }
      if (permissions.value.returns) {
        cardsDataTemp.push({
          id: "returns",
          icon: "/assets/icons/navigation/arrow-narrow-left",
          title: "internal.title_returns",
          description: "internal.description_returns",
          onClick: () => router.push(ROUTING.returns.url),
        });
      }
      if (permissions.value.warranty) {
        cardsDataTemp.push({
          id: "warranty",
          icon: "/assets/icons/navigation/annotation-alert",
          title: "internal.title_warranty",
          description: "internal.description_warranty",
          onClick: () => router.push(ROUTING.warranty.url),
        });
      }
      if (permissions.value.report) {
        cardsDataTemp.push({
          id: "reports",
          icon: "/assets/icons/navigation/pie-chart-04",
          title: "internal.title_reports",
          description: "internal.description_reports",
          onClick: () => router.push(ROUTING.report.url),
        });
      }
      if (permissions.value.onBehalf) {
        cardsDataTemp.push({
          id: ROUTING.onBehalf.name,
          icon: "/assets/icons/navigation/users",
          title: "internal.title_onBehalf",
          description: "internal.description_onBehalf",
          onClick: () => {
            router.push(ROUTING.onBehalf.url);
          },
        });
      }
      if (permissions.value.cms) {
        cardsDataTemp.push({
          id: "cms",
          icon: "/assets/icons/image-01",
          title: "internal.title_cms",
          description: "internal.description_cms",
          onClick: () => window.open(window.globalEnvVariables?.CMS_URL, "_blank"),
        });
      }
      if (permissions.value.etaf) {
        cardsDataTemp.push({
          id: "etaf",
          icon: "/assets/icons/navigation/user",
          title: "internal.title_etaf",
          description: "internal.description_etaf",
          onClick: () => router.push(ROUTING.etaf.url),
        });
      }
      if (permissions.value.etafConfiguration) {
        cardsDataTemp.push({
          id: "etafConfiguration",
          icon: "/assets/icons/navigation/user",
          title: "internal.title_etafConfiguration",
          description: "internal.description_etafConfiguration",
          onClick: () => router.push(ROUTING.etafConfig.url),
        });
      }
      if (permissions.value.adminTool) {
        cardsDataTemp.push({
          id: "admin",
          icon: "/assets/icons/navigation/zoom-in",
          title: "internal.title_admin",
          description: "internal.description_admin",
          onClick: () => goToLegacy(legacyIdMap.admin),
          legacyId: legacyIdMap.admin,
        });
      }
      if (permissions.value.sapControlReport) {
        cardsDataTemp.push({
          id: "sapControlReport",
          icon: "/assets/icons/navigation/pie-chart-04",
          title: "internal.title_sapControlReport",
          description: "internal.description_sapControlReport",
          onClick: () => router.push(ROUTING.sapControlReport.url),
        });
      }
      if (permissions.value.mmConfig) {
        cardsDataTemp.push({
          id: "mmConfig",
          icon: "/assets/icons/navigation/settings-01",
          title: "internal.title_mmConfig",
          description: "internal.description_mmConfig",
          onClick: () => router.push(ROUTING.mmConfig.url),
        });
      }
      if (permissions.value.b2bCms) {
        cardsDataTemp.push({
          id: "b2bCms",
          icon: "/assets/icons/image-01",
          title: "internal.title_b2bCms",
          description: "internal.description_b2bCms",
          onClick: () => router.push(ROUTING.b2bCms.url),
        });
      }
      if (permissions.value.vasMenu) {
        cardsDataTemp.push({
          id: "vasMenu",
          icon: "/assets/icons/navigation/file-attachment",
          title: "internal.title_vas",
          description: "internal.description_vas",
          onClick: () => {
            const eventProviderInstance = eventProvider.getEventProvider();
            eventProviderInstance.emit(eventProvider.EventChannel.brandSelectionModal, {
              isLoading: true,
            });

            getVasDocument({ locale: lng.value }).finally(() => {
              eventProviderInstance.emit(eventProvider.EventChannel.brandSelectionModal, {
                isLoading: false,
              });
            });
          },
        });
      }
    }
    return cardsDataTemp;
  });
  return {
    cardsData,
  };
}
