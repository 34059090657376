import { setInterceptorToAxios } from "@mercury/common";
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserPreferenceDTO {
  username?: string;
  language?: string;
}

export interface Brand {
  brandId?: string;
  roles?: string[];
  moduleConfigurationList?: ModuleConfiguration[];
  /** @uniqueItems true */
  distributionChannels?: string[];
}

export interface CustomerPermissionsDTO {
  /** @format int64 */
  userId?: number;
  legacyId?: string;
  userName?: string;
  passwordExpirationDate?: string;
  name?: string;
  language?: string;
  layout?: string;
  roles?: string[];
  companyType?: string;
  contacts?: UserContact[];
  creationReason?: string;
  oktaId?: string;
  portalPrivileges?: PortalPrivilege[];
  portalConfiguration?: UserPreferenceDTO[];
  moduleDTOList?: ModuleDTO[];
  activeBrands?: string[];
}

export interface ModuleConfiguration {
  custArea?: string;
  styleAreas?: string[];
  country?: string;
  customerDeliveryAddress?: string[];
  userType?: string;
  customers?: string[];
  permissions?: Privilege[];
}

export interface ModuleDTO {
  name?: string;
  roles?: string[];
  brands?: Brand[];
  moduleConfigurationList?: Record<string, object>[];
}

export interface PortalPrivilege {
  enabled?: string[];
  disabled?: string[];
  role?: string;
  customer?: string;
}

export interface Privilege {
  name?: string;
  enabled?: string;
}

export interface UserContact {
  type?: string;
  contact?: string;
}
export interface CustomerPermissionsRetrieveRequest {
  usernames?: string[];
}

export interface CustomerGeneralities {
  customerId?: string;
  legalName?: string;
  termsAndConditionsAcceptedBy?: string;
  /** @format date-time */
  termsAndConditionsTimestamp?: string;
  lastLogin?: string;
  legalRepCheckboxEnabled?: boolean;
}

export interface CustomerAddress {
  name1?: string;
  name2?: string;
  street1?: string;
  street2?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  language?: string;
  contactName?: string;
  telephone1?: string;
  vatCode?: string;
  vatComment?: string;
  emailInvoice?: string;
}

export interface CustomerContact {
  firstName?: string;
  lastName?: string;
  telephone?: string;
  function?: string;
  department?: string;
  email?: string;
}

export interface CustomerDTO {
  nodeId?: string;
  salesOrg?: string;
  channel?: string;
  division?: string;
  nodeDesc?: string;
  partnerFunction?: string;
  storeDetails?: StoreAddress;
  customerAddress?: CustomerAddress;
  payer?: Payer[];
  contacts?: CustomerContact[];
  children?: CustomerDTO[];
}

export interface Payer {
  bankName?: string;
  iban?: string;
  bicOrSwift?: string;
}

export interface StoreAddress {
  legalName?: string;
  legalAddress?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  emailAddress?: string;
}

export interface PortalUserDTO {
  /** @format int64 */
  id?: number;
  email?: string;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = setInterceptorToAxios(axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || window.globalEnvVariables?.BFF_BASE_PATH,
    }));
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl https://a171612d3c4d1446ea02564b5e3033f5-2021668928.eu-west-1.elb.amazonaws.com/mercury-bff
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  priv = {
    /**
     * No description
     *
     * @tags admin-tools-controller
     * @name SaveUserPreference
     * @request PUT:/priv/api/admin/tools/user/preference
     * @secure
     */
    saveUserPreference: (data: UserPreferenceDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/priv/api/admin/tools/user/preference`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User API
     * @name SaveLastLogin
     * @request POST:/priv/api/v1/user/save/last_login
     * @secure
     */
    saveLastLogin: (
      query: {
        customer: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/priv/api/v1/user/save/last_login`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer API
     * @name AcceptTermsAndConditions
     * @request POST:/priv/api/v1/customer/terms_and_conditions
     * @secure
     */
    acceptTermsAndConditions: (
      query: {
        customer: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/priv/api/v1/customer/terms_and_conditions`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of customers by the email user in the jwt
     *
     * @tags User API
     * @name GetUsersCustomers
     * @summary Gets user's customers
     * @request GET:/priv/api/v1/user/customers
     * @secure
     */
    getUsersCustomers: (params: RequestParams = {}) =>
      this.request<CustomerGeneralities[], any>({
        path: `/priv/api/v1/user/customers`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieves a legacy module link by the application, brand and language
     *
     * @tags Legacy Module API
     * @name GetLegacyModuleLink
     * @summary Gets a legacy module link
     * @request GET:/priv/api/v1/legacy/{application}
     * @secure
     */
    getLegacyModuleLink: (
      application:
        | "b2b"
        | "orderInquiry"
        | "returns"
        | "warranty"
        | "showroom"
        | "orderManagement"
        | "cms"
        | "etaf"
        | "admin"
        | "etafConfig"
        | "sapControlReport"
        | "mmConfig"
        | "b2bCms"
        | "showroomConfig"
        | "report"
        | "companyInfo"
        | "loginSecurity"
        | "documentInvoice"
        | "documentPayment"
        | "documentCreditNote"
        | "ticket"
        | "faq"
        | "onBehalf"
        | "document"
        | "notification"
        | "reportDownload"
        | "cloneUser",
      query: {
        brand: string;
        language: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/priv/api/v1/legacy/${application}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of all labels for a specific locale
     *
     * @tags label-controller
     * @name GetLabelsByLocaleAuthorized
     * @summary Returns all labels with a specific locale
     * @request GET:/priv/api/v1/labels/{locale}
     * @secure
     */
    getLabelsByLocaleAuthorized: (locale: string, params: RequestParams = {}) =>
      this.request<LabelContent[], any>({
        path: `/priv/api/v1/labels/${locale}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Gets a customer info, can also get on behalf of another. Password checking can be done by passing checkPassword=True.
     *
     * @tags Customer API
     * @name GetCustomerInfo
     * @summary Gets a customer info. Can also check for user password expiration.
     * @request GET:/priv/api/v1/customer
     * @secure
     */
    getCustomerInfo: (
      query: {
        /**
         * Indicates if the navigation is on behalf of someone else.
         * @example true
         */
        onBehalf: boolean;
        /**
         * The customer's id you want to navigate as.
         * @example 1806
         */
        customerOnBehalfId: string;
        /**
         * The current customer id.
         * @example 1703
         */
        currentCustomerId: string;
        /**
         * Check password expiry flag
         * @example false
         */
        checkPassword?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDTO[], CustomerDTO[]>({
        path: `/priv/api/v1/customer`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-tools-controller
     * @name GetAllCustomerPermissions
     * @request GET:/priv/api/admin/tools/permissions
     * @secure
     */
    getAllCustomerPermissions: (params: RequestParams = {}) =>
      this.request<CustomerPermissionsDTO, any>({
        path: `/priv/api/admin/tools/permissions`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  api = {
    /**
     * @description Retrieves the user from the okta JWT and updates the customer's permission associated with it.
     *
     * @tags AdminToolsApi
     * @name UpdateCustomerPermissions
     * @summary Updates the customer permissions
     * @request PUT:/api/admin/tools/permissions
     * @secure
     */
    updateCustomerPermissions: (data: CustomerPermissionsDTO, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/admin/tools/permissions`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves the user from the okta JWT and returns the permissions of the customer associated with it.
     *
     * @tags AdminToolsApi
     * @name GetCustomerPermissionsByUsernames
     * @summary Retrieves the current customer permissions
     * @request POST:/api/admin/tools/permissions/users/retrieve
     * @secure
     */
    getCustomerPermissionsByUsernames: (
      data: CustomerPermissionsRetrieveRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerPermissionsDTO[], CustomerPermissionsDTO[]>({
        path: `/api/admin/tools/permissions/users/retrieve`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a user by searching for the id inside the database.
     *
     * @tags User API
     * @name GetUserById1
     * @summary Gets a user by Id
     * @request GET:/api/v1/user/{userId}
     * @secure
     */
    getUserById1: (userId: number, params: RequestParams = {}) =>
      this.request<PortalUserDTO, PortalUserDTO>({
        path: `/api/v1/user/${userId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Checks for any password expire alert and marks them as read.
     *
     * @tags User API
     * @name ReportUserPasswordChange
     * @summary Turns off any password expire alert.
     * @request GET:/api/v1/user/password/change
     * @secure
     */
    reportUserPasswordChange: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/user/password/change`,
        method: "GET",
        ...params,
      }),
  };
}
