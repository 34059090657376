import { isValidUrl } from "@/utils/genericUtils";
import { ApiCms, getHeaders, mockService, sendErrorAnalytics } from "@mercury/common";

// const isEdgeBrowser = () => {
//   const userAgent = window.navigator.userAgent;
//   // Check for the new Chromium-based Edge (Edg) and old Edge (Edge)
//   return userAgent.includes("Edg") || userAgent.includes("Edge");
// };

interface getVasDocumentParams {
  locale: string;
  redirect?: boolean;
}
export const getVasDocument = ({
  locale,
  redirect = true,
}: getVasDocumentParams): Promise<string | void> => {
  const mock = window.globalEnvVariables?.USE_MOCK_CMS;
  const apiCms = new ApiCms();

  return (
    mock
      ? mockService("/mock/cms/vasDocument.json")
      : apiCms.priv.getContentsByTechnicalDocuments(locale)
  )
    .then((res: { data: string }) => {
      const path = res.data?.contents?.[0]?.grid?.rows?.[0].placements?.[0]?.items?.[0]?.data?.uri;
      const url = path.replace(
        "/caas/v1/media",
        window.globalEnvVariables?.BFF_BASE_PATH_CMS_RESOURCE,
      );
      console.log("url", { url });

      if (!path || !isValidUrl(url)) {
        throw new Error("getVasDocument - not valid url - " + path?.toString());
      }

      if (redirect) {
        //open the Excel file in preview mode
        const previewUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + url;
        window.open(previewUrl, "_blank");

        // Create a URL for the Blob and set it as the href of the anchor
        const a = document.createElement("a");
        a.href = url;
        // Append the anchor to the body (necessary for Firefox)
        document.body.appendChild(a);
        // Trigger the download by simulating a click
        a.click();
        document.body.removeChild(a);
      }

      return url;
    })
    .catch((err) => {
      console.error("getContentsByTechnicalDocuments", { err });

      //analytics
      sendErrorAnalytics({
        description: "Error getting the url to download the VAS Menu card & cost simulator",
        techInfo: err,
      });

      throw err;
    });
};
